<template>
  <div class="swiper">
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item,index) in imgList" :key="index">
        <div class="swiperItem">
          <ImgItem
            v-for="item in item"
            v-bind:key="item.id"
            :img="item.img"
            :name="item.name"
            :href="item.url"
          />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import list from "@/utils/company";
import ImgItem from "@/components/imgItem";
export default {
  name: "SwiperItem",
  components: {
    ImgItem,
  },
  data() {
    return {
      imgList: [],
      swiperOption: {
        loop: false,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  created() {
    let count = 0;
    let width = window.innerWidth;
    let iconNumber = 12;
    switch (true) {
      case width > 1700:
        iconNumber = 12;
        break;
      case width < 1700 && width > 1500:
        iconNumber = 9;
        break;
      case width < 1500 && width > 1200:
        iconNumber = 6;
        break;
      case width < 1200 && width > 1000:
        iconNumber = 4;
        break;
      case width < 1000 && width > 750:
        iconNumber = 2;
        break;
      case width < 750:
        iconNumber = 1;
        break;

      default:
        break;
    }

    for (let i = 0; i < list.length; i++) {
      let li = list[i];
      if (!this.imgList[count]) {
        this.imgList[count] = [];
      }
      this.imgList[count].push(li);
      if (this.imgList[count].length >= iconNumber) {
        count++;
      }
    }
  },
};
</script>
<style lang="less" scoped>
@myHeight: 70vh;

// 走马灯相关
.swiper {
  margin-top: 3%;
  width: 80%;
  padding: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .swiper-slide {
    box-sizing: border-box;
  }

  .swiper-pagination {
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 20px;
  }
}
.swiperItem {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 1800px) {
  .swiper .swiper-slide {
    height: 70vh;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .swiper .swiper-slide {
    height: 67vh;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .swiper .swiper-slide {
    height: 65vh;
  }
}
@media screen and (max-width: 1300px) {
  .swiper .swiper-slide {
    height: auto;
  }
}
</style>