<template>
  <div class="home">
    <p class="home-phone">咨询热线：15601982231 曾先生，18335832237 李先生</p>
    <div class="main-top">
      <!-- <img class="main-logo" :src="src" /> -->
      <div class="main-input">
        <!-- <div class="main-input-item">
          <span>产品</span>
          <span>资源</span>
          <span>服务与支持</span>
          <span>关于我们</span>
        </div>-->
        <div class="main-input-item">
          <input type="text" placeholder="您可以输入订单号、运单号、DN单号进行查询，最多可查询10条，以逗号或空格隔开。" />
        </div>
      </div>
    </div>
    <div class="swiper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import src from "@/assets/a56.png";
export default {
  name: "Home",
  data() {
    return {
      src: src,
    };
  },
};
</script>
<style lang="less" scoped>
@myHeight: 70vh;
.home {
  width: 100%;
  height: 100%;
}
// 最上方电话
.home-phone {
  padding-top: 13px;
  width: 83%;
  text-align: right;
  font-size: 12px;
}

// 上方搜索框相关
.main-top {
  color: #fff;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .main-logo {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin-right: 50px;
    font-size: 20px;
  }
  .main-input {
    width: 50%;
    .main-input-item {
      text-align: left;
      margin-bottom: 10px;
      span {
        margin: 0 20px;
        cursor: pointer;
        color: yellow;
      }
      input {
        width: 90%;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        border: none;
        outline: none;
        padding-left: 20px;
        font-size: 15px;
        background-color: #eee;
        &:focus {
          background-color: #fff;
        }
      }
    }
  }
}
</style>