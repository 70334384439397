<template>
  <div id="app">
    <router-view />
    <!--  备案  -->
    <div class="filing-footer">
      <span>
        A56.net-© Copyright 2021 备案号:
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        >沪ICP备08016865号-4</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body {
  background: url(./assets/background.jpg) no-repeat;
  background-size: 100%;
  background-repeat: repeat-y;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: yellow;
  // background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
// 底部信息
.filing-footer {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  a {
    color: yellow;
    text-decoration: none;
  }
}
@media screen and (min-width: 1300px) {
  #app {
    height: 100vh;
  }
}
@media screen and (max-width: 1300px) {
  #app {
    height: 100vh;
  }
}
@media screen and (max-width: 700px) {
  #app {
    height: 115vh;
  }
}

.swiper-pagination-bullet {
  margin: 0 10px !important;
  width: 8px !important;
  height: 8px !important;
  opacity: 0.5 !important;
  background-color: red !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
}
</style>
