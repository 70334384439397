import img1 from "@/assets/img/img1.png";
import img2 from "@/assets/img/img2.png";
import img3 from "@/assets/img/img3.png";
import img4 from "@/assets/img/img4.png";
import img5 from "@/assets/img/img5.png";
import img8 from "@/assets/img/img8.png";
import img9 from "@/assets/img/img9.png";

export default [
  { id: 1, img: img1, name: "晋宝物流GLW", url: "http://glw.a56.net" },
  { id: 2, img: img2, name: "闪链医疗SMS", url: "http://sci-line.a56.net" },
  { id: 3, img: img3, name: "中茶连锁OIC", url: "http://chinatea.a56.net" },
  { id: 4, img: img4, name: "普新贸易OIC", url: "http://glp.a56.net" },
  { id: 5, img: img5, name: "赛默飞(上海)EZWMS", url: "http://vmm.a56.net" },
  { id: 6, img: img5, name: "赛默飞(苏州)EZWMS", url: "http://cmc.a56.net" },
  { id: 7, img: img8, name: "复胜贸易OIC", url: "http://fusun.a56.net" },
  { id: 8, img: img1, name: "晋宝物流WMS", url: "http://lms.a56.net" },
  { id: 9, img: img1, name: "晋宝物流FMS", url: "http://fms.a56.net" },
  { id: 10, img: img9, name: "砾特建材VMM", url: "http://litokol.a56.net" },
];
