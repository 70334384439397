<template>
  <div class="imgItem">
    <img :src="img" v-show="img" class="img" @click="openPage(href)" />
    <span v-show="!img" class="img imgSpan" @click="openPage(href)">{{name}}</span>
    <br />
    <span @click="openPage(href)">{{name}}</span>
  </div>
</template>
<script>
export default {
  name: "ImgItem",
  props: {
    img: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    href: {
      type: String,
      require: "",
    },
  },
  methods: {
    openPage(href) {
      window.location.href = href;
    },
  },
};
</script>
<style lang="less" scoped>
.imgItem {
  float: left;
  margin-left: 30px;
  margin-top: 20px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  .img {
    width: 230px;
    height: 90px;
    border-radius: 15px;
    transition: all 0.5s;
    box-sizing: border-box;
    margin-bottom: 5px;
    font-size: 40px;
    display: inline-block;
    &:hover {
      transform: scale(1.1);
    }
  }
  span {
    color: #ffcd17;
    text-shadow: 0px 3px #000;
    font-weight: bolder;
    font-size: 16px;
  }
}
@media screen and (min-width: 1800px) {
  .imgItem {
    width: 340px;
    height: 142px;
  }
}
@media screen and (min-width: 1700px) and (max-width: 1800px) {
  .imgItem {
    width: 300px;
    height: 142px;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .imgItem {
    width: 350px;
    height: 142px;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .imgItem {
    width: 300px;
    height: 130px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .imgItem {
    width: 30%;
    height: 130px;
  }
}
@media screen and (max-width: 1100px) {
  .imgItem {
    width: 45%;
    height: 130px;
  }
}
</style>