import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import SwiperItem from "@/views/swiperItem/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "index",
        name: "SwiperItem",
        component: SwiperItem,
      },
    ],
    redirect: "/index",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
